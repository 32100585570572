import { hydrate } from '@bespohk/lib';
import PlateFinish from './plate-finish';
import PanelType from './panel-type';

class Route {
  id: number;
  minutes: number;
  code: string;
  routeId: string;
  enabled: boolean;

  @hydrate(PlateFinish)
  plateFinish: PlateFinish;

  @hydrate(PanelType)
  panelType: PanelType;

  public toString(): string {
    return this.code;
  }
}

type RouteFinderPredicate = (route: Route) => boolean;

class RouteFinder {
  predicates: RouteFinderPredicate[] = [];

  constructor(predicates: RouteFinderPredicate[] = []) {
    this.predicates = predicates;
  }

  addPredicate(predicate: RouteFinderPredicate) {
    this.predicates.push(predicate);

    return this;
  }

  isEnabled() {
    return this.addPredicate((route) => route.enabled);
  }

  hasMinutes(minutes: number) {
    return this.addPredicate((route) => route.minutes === minutes);
  }

  hasPanelType(panelType: PanelType) {
    return this.addPredicate((route) => route.panelType.id === panelType?.id);
  }

  isStainlessSteel() {
    return this.addPredicate(
      (route) => route.plateFinish && route.plateFinish.isStainlessSteel,
    );
  }

  isType(compOrPlate: 'comp' | 'plate') {
    return this.addPredicate((route) =>
      route.code.toLowerCase().includes(compOrPlate),
    );
  }

  clone() {
    return new RouteFinder([...this.predicates]);
  }

  filter(data: any[]) {
    return data.filter((item) => this.predicates.every((pred) => pred(item)));
  }

  find(data: any[]) {
    return data.find((item) => this.predicates.every((pred) => pred(item)));
  }
}

const findRoute = (
  routes: Route[],
  minutes: number,
  panelType?: PanelType,
  plateFinish?: PlateFinish,
  compOrPlate?: 'comp' | 'plate',
) => {
  const routeFinder = new RouteFinder().isEnabled();

  if (panelType) {
    routeFinder.hasPanelType(panelType);
  }

  if (compOrPlate) {
    routeFinder.isType(compOrPlate);
  }

  if (plateFinish?.isStainlessSteel) {
    routeFinder.isStainlessSteel();
  }

  // We do this because if there is no matching route, it needs to get the next highest
  const allRoutesMatchingNoMinutes = routeFinder.clone();

  routeFinder.hasMinutes(minutes);

  let route = routeFinder.find(routes);

  if (!route) {
    route = allRoutesMatchingNoMinutes.filter(
      routes.sort((r1, r2) => (r1.minutes < r2.minutes ? -1 : 1)),
    )?.[0];
  }

  return route;
};

export { Route, findRoute };
export default Route;
