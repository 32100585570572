import Document, { BOM, OrderLine } from '@app/models/bom';
import { zerofill } from '@app/helpers/strings';

import { Project } from '@app/models/project';
import { mapTerritoryToCode } from '@app/models/territory';

const formatDate = (d: Date): string =>
  `${d.getFullYear()}${zerofill(d.getMonth() + 1, 2)}${zerofill(
    d.getDate(),
    2,
  )}`;

const DEFAULT_ITEM_GROUP = 'PAMF';
const DEFAULT_WAREHOUSE = 'SYD';

const clean = (value: string, force = true): string => {
  return (value && value.toString().indexOf('&') >= 0) || force
    ? `<![CDATA[${value}]]>`
    : value;
};

const trimPronto = (value: string) => {
  if (!value) {
    return '';
  }

  return value.substring(0, 19);
};

const generate = (project: Project): Document => {
  const document: Document = new Document();

  document.documentHeader = {
    docType: 'MORRIS Quotation',
    docId: project.uuid,
    docRevisionNo: project.revision,
    docQuote: clean(project.salesOrderNumber, false),
  };

  document.project = {
    projectCode: clean(project.code, false),
    projectName: clean(trimPronto(project.name), false),
    projectPriority: project.priority,
  };

  const orderLines: OrderLine[] = [];

  document.order = {
    orderHeader: {
      orderValidTo: formatDate(project.validUntilDate),
      orderDate: formatDate(project.updatedDate),
      orderRep: clean(project.bdm.code, false),
      orderWarehouse: DEFAULT_WAREHOUSE,
      orderTerritory: mapTerritoryToCode(project.customer.state),
      orderReference: clean(trimPronto(project.purchaseOrderNumber), false),
      customer: {
        customerCode: project.customer.accountCode,
        customerName: clean(project.customer.businessName, false),
      },
      delivery: {
        deliveryStreet1: clean(project.customer.address, false),
        deliveryStreet2: '',
        deliverySuburb: clean(project.customer.suburb),
        deliveryState: mapTerritoryToCode(project.customer.state),
        deliveryCountry: clean(project.customer.country),
        deliveryPostcode: project.customer.postcode,
      },
    },
    orderLines: {
      line: orderLines,
    },
  };

  const boms: BOM[] = [];
  document.boms = { bom: boms };

  let lineNo = 1;

  const { activePanelSets: panelSets } = project;

  panelSets.forEach((panelSet) => {
    // Panel Set
    const { panel, backplate } = panelSet;

    const panelSetPartNumber = `${panel.panelType.shortName} PS ${panel.mspReference}`;

    // Order Lines: Panel

    orderLines.push({
      lineNo: lineNo,
      stockCode: panelSet.panelPartNumber,
      customerPartNo: clean(panel.mspReference, false),
      lineDescription: clean(panelSetPartNumber, false),
      uom: 'EACH',
      orderQuantity: panel.quantity,
      discountRate: panel.expectedPrice ? 0 : panel.discount,
      itemPrice: panelSet.totalCosts.panel.unit,
      lineTotal: panelSet.discountedTotalCosts.panel.total,
    });

    // Order Lines: Wallbox

    const wallboxPartNumberDescription = `${panel.panelType.shortName} WB ${panel.mspReference}`;

    orderLines.push({
      lineNo: lineNo + 1,
      stockCode: backplate.partNumber,
      customerPartNo: clean(panel.mspReference, false),
      lineDescription: clean(wallboxPartNumberDescription, false),
      uom: 'EACH',
      orderQuantity: panel.quantity,
      discountRate: panel.expectedPrice ? 0 : panel.discount,
      itemPrice: panelSet.totalCosts.backplate.unit,
      lineTotal: panelSet.discountedTotalCosts.backplate.total,
    });

    lineNo += 2;

    // BOM

    const { panelSubAssemblies: panelSetSubAssemblies, route: panelSetRoute } =
      panelSet;

    const panelSetBom: BOM = {
      bomHeader: {
        bomItemCode: panel.partNumber,
        bomDescription: clean(panelSetPartNumber, false),
        bomDrawingNo: panel.yyNumber,
        bomItemGroup: DEFAULT_ITEM_GROUP,
        bomDefaultRouteId: panelSetRoute?.routeId,
        bomRouteCode: panelSetRoute?.code,
      },
      bomComponents: {
        component: [],
      },
      // bomRouting: {
      //   route: [
      //     {
      //       rteSeqNo: 1,
      //       rteOpCode: panel.partNumber,
      //       rteQuantityPer: panel.quantity,
      //       rteLabourTime: panelSetRoutingMinutes,
      //       rteOverheadTime: 0,
      //     },
      //   ],
      // },
    };

    let j = 1;

    const { components } = panel;

    // BOM: Components
    components.forEach((component) => {
      if (component.isGas) {
        return; // Ignore gas from BOM
      }
      const itemCode = component.bomPartNumber;
      // Handle multiple part numbers
      const itemCodes = itemCode.split(',').map((code) => code.trim());
      itemCodes.forEach((code) => {
        const existingBomComponent = panelSetBom.bomComponents.component.find(
          (bomComponent) => bomComponent.cmpItemCode === code,
        );
        if (existingBomComponent) {
          existingBomComponent.cmpQuantity++;

          return;
        }

        panelSetBom.bomComponents.component.push({
          cmpSeqNo: j,
          cmpItemCode: code,
          cmpDescription: component.bomDescription,
          cmpQuantity: 1,
        });

        j++;
      });
    });

    // BOM: Hbars

    if (panel.hbars.length && panel.hbar) {
      const hbar = panel.hbars[0];
      panelSetBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: hbar.partNumber,
        cmpDescription: hbar.description,
        cmpQuantity: panel.hbars.length,
      });
      j++;
    }

    // BOM: Grids

    /*
    // Grids should not be displayed in the BOM with new Grid kits
    panelSet.gridContainer.grids.forEach(grid => {
      const itemCode = grid.code;
      const existingBomComponent = panelSetBom.bomComponents.component.find(
        bomComponent => bomComponent.cmpItemCode === itemCode,
      );
      if (existingBomComponent) {
        existingBomComponent.cmpQuantity++;
        return;
      }
      panelSetBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: grid.code,
        cmpDescription: grid.description,
        cmpQuantity: 1,
      });
      j++;
    });
    */

    // BOM: Panel Sub Assemblies

    panelSetSubAssemblies.forEach((subAssembly) => {
      panelSetBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: subAssembly.itemCode,
        cmpDescription: subAssembly.description,
        cmpQuantity: subAssembly.quantity,
      });
      j++;
    });

    // Front Plate as component of PanelSet BOM
    const xcodes = panel.xcode?.split('#');

    xcodes.forEach((xcode) => {
      panelSetBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: xcode || panel.punchingLayoutJoined,
        cmpDescription: 'Front Plate',
        cmpQuantity: 1,
      });
      j++;
    });

    boms.push(panelSetBom);

    // BOM: Front plate

    j = 1;
    const {
      frontPlateSubAssemblies: frontPlateSubAssemblies,
      plateFinishRoute,
    } = panelSet;

    frontPlateSubAssemblies.forEach((subAssembly, i) => {
      const itemCode = xcodes[i] || panel.yyNumber;
      const frontPlateBom: BOM = {
        bomHeader: {
          bomItemCode: itemCode,
          bomDescription: 'Front Plate',
          bomDrawingNo: itemCode,
          bomItemGroup: DEFAULT_ITEM_GROUP,
          bomDefaultRouteId: plateFinishRoute?.routeId,
          bomRouteCode: plateFinishRoute?.code,
        },
        bomComponents: {
          component: [],
        },
      };
      subAssembly.forEach((assembly) => {
        frontPlateBom.bomComponents.component.push({
          cmpSeqNo: j,
          cmpItemCode: assembly.itemCode,
          cmpDescription: assembly.description,
          cmpQuantity: assembly.quantity,
        });
        j++;
      });

      boms.push(frontPlateBom);
    });

    // BOM: Wallboxes

    if (backplate.isStandardPart) {
      return; // Ignore standard part numbers from BOM
    }

    j = 1;

    const { subAssemblies: wallboxSubAssemblies } = backplate;
    const { wallboxRoute: backplateRoute } = panelSet;

    const wallboxBom: BOM = {
      bomHeader: {
        bomItemCode: backplate.partNumber,
        bomDescription: wallboxPartNumberDescription,
        bomDrawingNo: panel.yyNumber,
        bomItemGroup: DEFAULT_ITEM_GROUP,
        bomDefaultRouteId: backplateRoute?.routeId,
        bomRouteCode: backplateRoute?.code,
      },
      bomComponents: {
        component: [],
      },
      // bomRouting: {
      //   route: [
      //     {
      //       rteSeqNo: 1,
      //       rteOpCode: DEFAULT_OP_CODE,
      //       rteQuantityPer: 1,
      //       rteLabourTime: backplate.routingMinutes,
      //       rteOverheadTime: 0,
      //     },
      //   ],
      // },
    };

    // Wallboxes are no longer included in the BOM, only the sub-assemblies
    // const { wallboxes } = backplate;
    // wallboxes.forEach(wallbox => {
    //   if (wallbox.gasOnly) {
    //     return;
    //   }
    //   const itemCode = wallbox.code;
    //   const existingBomComponent = wallboxBom.bomComponents.component.find(
    //     bomComponent => bomComponent.cmpItemCode === itemCode,
    //   );
    //   if (existingBomComponent) {
    //     existingBomComponent.cmpQuantity++;
    //     return;
    //   }
    //   wallboxBom.bomComponents.component.push({
    //     cmpSeqNo: j,
    //     cmpItemCode: itemCode,
    //     cmpDescription: wallbox.humanDescription,
    //     cmpQuantity: 1,
    //   });
    //   j++;
    // });

    // BOM: Wallbox Sub Assemblies
    wallboxSubAssemblies.forEach((subAssembly) => {
      wallboxBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpDescription: clean(subAssembly.description),
        cmpItemCode: subAssembly.itemCode,
        cmpQuantity: subAssembly.quantity,
      });
      j++;
    });

    boms.push(wallboxBom);
  });

  console.log(document);

  return document;
};

export { generate };
