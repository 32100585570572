import Backplate, { INVALID_GANG, SubAssembly } from '@app/models/backplate';
import { Wallbox, Definition } from '@app/models/wallbox';
import {
  areSpacers,
  areSpacersAndGas,
  width,
  areRcds,
  areSpacersAndOwnWallbox,
  containsAtLeastOneGas,
  lastComponentIsSpacer,
} from '@app/helpers/components';

import ClampRail from '@app/models/clamp-rail';
import { Component } from '@app/models/component';
import Divider from '@app/models/divider';
import { Panel } from '@app/models/panel';
import { PopRivet } from '@app/models/pop-rivet';
import { Screw, findScrew } from '@app/models/screw';
import Strapping from '@app/models/strapping';
import { nearestMultiple } from '@app/helpers/maths';
import { rehydrate } from '@bespohk/lib';
import { logger } from '@app/helpers/log';
import { Lookups } from './lookups';

const log = logger('services:backplate');

type GeneratedWallboxSubAssembly = SubAssembly & {
  dividers: number;
  joins: number;
};

const invalidDefinition = () => ({
  uuid: `${Date.now()}`,
  cost: 0,
  gang: INVALID_GANG,
  components: [],
  createdDate: new Date(),
  updatedDate: new Date(),
});

const prettyLogWallboxes = (wallboxes: Wallbox[]) => {
  wallboxes.forEach((wallbox, i) => {
    if (!wallbox) {
      log(`Something missing at Wallbox ${i}`);

      return;
    }
    log(`Wallbox ${i}:`, {
      width: wallbox.width,
      uuid: wallbox.uuid,
      description: wallbox.description,
      components: wallbox.components.map((component) => component.description),
    });
  });
};

const findWallboxForComponents = (
  components: Component[],
  wallboxDefinitions: Definition[],
  rule: Rule,
): Wallbox | null => {
  const componentsWidth: number = width(components);
  const gasOnly: boolean =
    areSpacersAndGas(components) && !areSpacers(components);
  const isRcdBank = areRcds(components);
  const definition: Definition = wallboxDefinitions.find(
    (def) =>
      def.width === componentsWidth &&
      def.gasOnly === gasOnly &&
      def.rcdBank === isRcdBank,
  );

  return definition
    ? rehydrate(Wallbox, {
        ...definition,
        components,
        rule: rule.name,
      })
    : null;
};

type Rule = {
  name: string;
  validate: (
    remainingComponents: Component[],
    existingComponents: Component[],
    maxBoxSize: number,
    panel: Panel,
    direction: number,
  ) => boolean;
};

const rules: Rule[] = [
  {
    name: 'Row Start',
    validate: (components) => components.length && components[0].startsRow,
  },
  {
    name: 'Forced Wallbox end',
    validate: (components) =>
      components.length &&
      (components[0].forcedWallboxEnd || components[0].isOwnWallbox),
  },
  {
    name: 'Max size reached',
    validate: (_, components, maxBoxSize) => width(components) >= maxBoxSize,
  },
  {
    name: 'Existing components are in own wallbox',
    validate: (_, existingComponents) => {
      return areSpacersAndOwnWallbox(existingComponents);
    },
  },
  {
    name: 'Existing components are Gas with spacers and next component is not',
    validate: (remainingComponents, existingComponents, _, __, direction) => {
      if (!remainingComponents.length) {
        return false;
      }
      if (direction === -1) {
        return (
          areSpacersAndGas(existingComponents) &&
          containsAtLeastOneGas(existingComponents)
        );
      }
      const nextComponent = remainingComponents[0];

      return (
        areSpacersAndGas(existingComponents) &&
        !(nextComponent.isSpacer || nextComponent.isGas) &&
        containsAtLeastOneGas(existingComponents)
      );
    },
  },
  {
    name: 'Existing components are not Gas and the next component is',
    validate: (remainingComponents, existingComponents) => {
      if (!remainingComponents.length) {
        return false;
      }

      const nextComponent = remainingComponents[0];

      return nextComponent.isGas && !areSpacersAndGas(existingComponents);
    },
  },
];

const generatePartSubAssemblies = (
  backplate: Backplate,
  clampRails: ClampRail[],
  screws: Screw[],
  strappings: Strapping[],
  popRivets: PopRivet[],
  dividers: Divider[],
): SubAssembly[] => {
  const subAssemblies: SubAssembly[] = [];
  const { panel } = backplate;
  const { dimensions } = panel;

  // Clamp Rail
  const clampRail: ClampRail = clampRails.find((cr) => cr.enabled);
  if (clampRail) {
    subAssemblies.push({
      itemCode: clampRail.partNumber,
      description: clampRail.description,
      quantity: (dimensions.width * 2) / 1000,
    });
  }

  // Screws
  const screw: Screw = findScrew(screws, 'wallbox');
  if (screw) {
    const screwCount = nearestMultiple(dimensions.width / 100);
    const requiredScrews = (screwCount < 6 ? 6 : screwCount) / 6;

    subAssemblies.push({
      itemCode: screw.partNumber,
      description: screw.description,
      quantity: requiredScrews,
    });
  }

  // Dividers
  const divider: Divider = dividers.find((d) => d.enabled);
  const dividerCount = backplate.wallboxes.reduce((count, wallbox) => {
    count += wallbox.dividers.length;

    return count;
  }, 0);

  if (dividerCount && divider) {
    subAssemblies.push({
      itemCode: divider.partNumber,
      description: divider.description,
      quantity: dividerCount,
    });
  }

  // Strapping
  if (panel.isVertical) {
    const rows = panel.rows.length;
    const strapping: Strapping = strappings.find(
      (s) => s.enabled && s.centers === panel.strappingSize && s.rows === rows,
    );
    const popRivet: PopRivet = popRivets.find((pr) => pr.enabled);
    if (popRivet) {
      subAssemblies.push({
        itemCode: popRivet.partNumber,
        description: popRivet.description,
        quantity: rows * 4,
      });
    }
    if (strapping) {
      subAssemblies.push({
        itemCode: strapping.partNumber,
        description: strapping.description,
        quantity: 2,
      });
    }
  }

  return subAssemblies;
};

const createUnknownWallbox = (components: Component[]): Wallbox => {
  const unknownWallbox = rehydrate(Wallbox, {
    width: width(components),
    ...invalidDefinition(),
    components: [...components],
  });

  return unknownWallbox;
};

const createWallboxForComponentsInReverse = (
  panel: Panel,
  components: Component[],
  definitions: Definition[],
  maxSize: number,
): Wallbox[] => {
  const wallboxes: Wallbox[] = [];
  let componentStack: Component[] = [];
  while (components.length) {
    const component = components.pop();
    componentStack.unshift(component);
    rules.forEach((rule) => {
      if (rule.validate(componentStack, componentStack, maxSize, panel, -1)) {
        const foundWallbox = findWallboxForComponents(
          componentStack,
          definitions,
          rule,
        );
        if (foundWallbox) {
          wallboxes.unshift(foundWallbox);
        } else {
          wallboxes.unshift(createUnknownWallbox(componentStack));
        }
        componentStack = [];
      }
    });
  }

  if (componentStack.length) {
    const foundWallbox = findWallboxForComponents(componentStack, definitions, {
      name: 'Last component',
      validate: () => true,
    });
    if (foundWallbox) {
      wallboxes.unshift(foundWallbox);
    } else {
      wallboxes.unshift(createUnknownWallbox(componentStack));
    }
  }

  return wallboxes;
};

const createWallboxForComponents = (
  wallboxes: Wallbox[],
  panel: Panel,
  panelComponents: Component[],
  definitions: Definition[],
  maxSize: number,
) => {
  const components: Component[] = [...panelComponents];
  let componentStack: Component[] = [];
  let lastWallbox: Wallbox;
  const regenerateWallboxIndexes: number[] = [];
  while (components.length) {
    const currentComponent = components.shift();
    componentStack.push(currentComponent);
    let skipRules = false;

    rules.forEach((rule) => {
      if (skipRules) {
        return;
      }

      if (rule.validate(components, componentStack, maxSize, panel, 1)) {
        if (
          lastWallbox &&
          !lastWallbox.isValid &&
          lastComponentIsSpacer(lastWallbox.components) &&
          !currentComponent.rowStart
        ) {
          const lastComponent = lastWallbox.components.pop();
          componentStack.unshift(lastComponent);
          regenerateWallboxIndexes.push(wallboxes.length - 1);
        }
        const wallbox = findWallboxForComponents(
          componentStack,
          definitions,
          rule,
        );
        if (wallbox) {
          wallboxes.push(wallbox);
          lastWallbox = wallbox;
        } else {
          const unknownWallbox = createUnknownWallbox(componentStack);
          wallboxes.push(unknownWallbox);
          lastWallbox = unknownWallbox;
        }
        componentStack = [];
        skipRules = true;
      }
    });
  }

  if (componentStack.length) {
    const wallbox = findWallboxForComponents(componentStack, definitions, {
      name: 'Rules expended',
      validate: () => true,
    });
    if (wallbox) {
      wallboxes.push(wallbox);
    } else {
      wallboxes.push(createUnknownWallbox(componentStack));
    }
  }

  wallboxes.forEach((wallbox, index) => {
    if (wallbox.isValid) {
      return;
    }

    const newWallboxes = createWallboxForComponentsInReverse(
      panel,
      wallbox.components,
      definitions,
      maxSize,
    );

    if (newWallboxes.length) {
      wallboxes.splice(index, 1, ...newWallboxes);
    }
  });
};

const generateSubassemblies = (
  backplate: Backplate,
  lookups: Lookups,
): SubAssembly[] => {
  const { wallboxes, panel } = backplate;
  const temporarySubAssemblies = wallboxes.reduce(
    (
      assemblies: GeneratedWallboxSubAssembly[],
      wallbox: Wallbox,
      index: number,
    ) => {
      const dividers = wallbox.dividers.length;
      let nextWallbox: Wallbox = null;
      try {
        nextWallbox = wallboxes[index + 1];
      } catch (e) {
        // No wallbox
      }
      let prevWallbox: Wallbox = null;
      try {
        prevWallbox = wallboxes[index - 1];
      } catch (e) {
        // No wallbox
      }

      let joins = 0;
      // let joins = total === 1 ? 0 : index > 0 && index < total - 1 ? 2 : 1;
      if (nextWallbox && !nextWallbox.startsRow) {
        joins++;
      }
      if (prevWallbox && !wallbox.startsRow) {
        joins++;
      }
      if (!wallbox.panelType) {
        log('No panelType associated with this wallbox', wallbox, panel);

        return assemblies;
      }

      assemblies.push({
        dividers,
        joins,
        itemCode: wallbox.standardPartNumber,
        description: `${wallbox.panelType.subAssemblyPrefix} ${wallbox.humanGang} WB CW `,
        quantity: 1,
      });

      return assemblies;
    },
    [],
  );

  temporarySubAssemblies.forEach((assembly) => {
    const joins = `J${assembly.joins}`;
    // -J0-DN
    // -JNDN
    const dividers = assembly.dividers
      ? `${assembly.joins ? '' : ''}D${assembly.dividers}`
      : '';
    assembly.itemCode += `-${joins}${dividers}`;
    assembly.description += `${assembly.dividers} DIVIDER & ${assembly.joins} JOIN`;
  });

  const subAssemblies: SubAssembly[] = temporarySubAssemblies.map(
    (assembly) => {
      const wallboxSubAssembly = lookups.wallboxSubassemblies.find(
        (sa) => sa.code === assembly.itemCode,
      );

      const subAssembly: SubAssembly = {
        description: assembly.description,
        itemCode: assembly.itemCode,
        quantity: 1,
      };

      if (wallboxSubAssembly?.routeAllocation) {
        subAssembly.routeAllocation = wallboxSubAssembly.routeAllocation;
      }

      return subAssembly;
    },
  );

  return subAssemblies;
};

const generate = (panel: Panel, lookups: Lookups): Backplate => {
  const {
    wallboxes: wallboxDefinitions,
    clampRails,
    screws,
    strappings,
    popRivets,
    dividers,
  } = lookups;

  const maxBoxSize: number = wallboxDefinitions.reduce(
    (p, wallbox) => Math.max(p, wallbox.width),
    0,
  );
  const wallboxes: Wallbox[] = [];

  createWallboxForComponents(
    wallboxes,
    panel,
    panel.components,
    wallboxDefinitions,
    maxBoxSize,
  );

  const backplate: Backplate = new Backplate();
  backplate.panel = panel;
  backplate.wallboxes = wallboxes;
  prettyLogWallboxes(backplate.wallboxes);
  // Care here, could get a bit nasty with recursion

  const wallboxSubassemblies = generateSubassemblies(backplate, lookups);
  const partSubAssemblies = generatePartSubAssemblies(
    backplate,
    clampRails,
    screws,
    strappings,
    popRivets,
    dividers,
  );
  backplate.subAssemblies = [...wallboxSubassemblies, ...partSubAssemblies];

  log(panel.mspReference, backplate);

  return backplate;
};

export { generate };
