class PanelType {
  readonly id: number;
  name: string;
  shortName: string;
  description: string;
  subAssemblyPrefix: string;
  active: boolean;

  public toString(): string {
    return this.name;
  }

  public get isAlphamax() {
    return isAlphamax(this);
  }
}

const isAlphamax = (panelType: PanelType) =>
  panelType.name.toLowerCase() === 'alphamax';

export { PanelType, isAlphamax };

export default PanelType;
