class PlateFinish {
  readonly id: number;
  name: string;
  partNumber: string;
  supplierCode: string;

  public toString(): string {
    return this.name;
  }

  public get isStainlessSteel(): boolean {
    return isStainlessSteel(this);
  }
}

const isStainlessSteel = (plateFinish: PlateFinish) =>
  plateFinish.name.toLowerCase().includes('stainless');

export { PlateFinish, isStainlessSteel };

export default PlateFinish;
