import { toTitleCase } from '@bespohk/lib';

type Type = 'front_plate' | 'wallbox' | 'component';

class Screw {
  description: string;
  partNumber: string;
  enabled: boolean;
  type: Type;

  public get humanType(): string {
    return toTitleCase(this.type);
  }

  public toString(): string {
    return this.description;
  }
}

const findScrew = (screws: Screw[], type: Type, enabled: boolean = true) =>
  screws.find((screw) => screw.enabled === enabled && screw.type === type);

export default Screw;
export { Screw, findScrew };
