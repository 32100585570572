class Series {
  readonly id: number;
  name: string;
  gpoCentres: number[];

  public toString(): string {
    return this.name;
  }

  public get isBSeries() {
    return isBSeries(this);
  }
}

const isBSeries = (series: Series) => series.name.toLowerCase() === 'b series';

export { Series, isBSeries };

export default Series;
